/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0ff72868-c7ce-46d3-b668-3e1deca8d5e1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_m1Xrl1nsr",
    "aws_user_pools_web_client_id": "61uv57canugftufasjstdcu9a0",
    "oauth": {}
};


export default awsmobile;
